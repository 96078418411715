<template>
	<div class="page">
		<div class="w1200 allbooks_page">
			<div class="block58"></div>
			<div class="book_conbox">
				<div class="titlename_box">
					<div class="itemobj fontsize14" @click="handlebookdtype(index)" :class="{'active':index==bookdtypeindex}" v-for="(item,index) in bookdtypeList" :key="index">
						{{item}}
					</div>
				</div>
				<div style="width: 100%;" v-if="bookdtypeindex==0">
					<div class="block20"></div>
					<div class="allbooklist_box">
						<div class="itemobj" v-for="(item,index) in allbookdList" :key="index">
							<div class="tips fontsize16" :class="{'active':item.ifhave>=0}">
								{{item.ifhave>=0?"已拥有":"未拥有"}}
							</div>
							<div class="imgbox">
								<img class="img100" :src="item.img1" :alt="item.title"/>
							</div>
							<el-tooltip class="item" effect="dark" :content="item.title" placement="top">
							    <div class="titlename fontsize20 clamp">{{item.title}}</div>
							</el-tooltip>
						</div>
					</div>
					<!-- 空 -->
					<div class="data_empty_box" style="padding-top: 68px;" v-if="allbookdList.length<=0">
						<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
						<div class="text">暂无数据</div>
					</div>
					<!-- 空 -->
					<div class="paginationbox">
						<div class="btn1"></div>
					    <el-pagination
					      @current-change="handleCurrentChange"
					      :current-page.sync="formData.currentPage"
					      :page-size="formData.pageSize"
					      layout="prev, pager, next, jumper"
					      :total="total">
					    </el-pagination>
					</div>
				</div>
				<div class="goodslist_bos" v-if="bookdtypeindex==1">
					<div class="itemlist">
						<div class="vip-group" v-if="vipgoods.length>0">
							<div class="vip-item" :class="{'vip-item-checked': index==checkedindex}" @click="changeSelect(index)"
								v-for="(item,index) in vipgoods" :key="index">
								<div class="yh-img fontsize14">限时优惠</div>
								<div class="vip-label fontsize16">{{item.goodsName}}</div>
								<div class="vip-label_desc" v-if="item.extraObj">{{item.extraObj}}</div>
								<div class="price-text fontsize20">￥{{item.goodsPrice}}</div>
								<div class="origin-price fontsize14">原价：￥{{item.goodsOriPrice}}</div>
							</div>
						</div>
					</div>
					<div class="block30"></div>
					<div class="desc-content" v-if="vipgoods.length>0">
						<div class="desc-title">套餐说明</div>
						<div class="desc-item" v-html="vipgoods[checkedindex].buyKnow"></div>
					</div>
					<div class="block20"></div>
					<div class="desc-content" v-if="vipgoods.length>0">
						<div class="desc-title">套餐包含训练词书</div>
						<div class="desc-itemlist">
							<div class="itemobj" v-for="(item,index) in vipgoods[checkedindex].booksList" :key="index">
								{{item.title}}
							</div>
						</div>
					</div>
					<div class="bottom-group" v-if="vipgoods.length>0">
						<div class="first-col">
							<el-checkbox class="radio" v-model="isagree"></el-checkbox>
							<span class="pointer" @click="navigato('agreement')">选择同意《{{bsnName}}用户协议》</span>
						</div>
						<div class="btnlist">
							<div class="sub-duihuan" @click="handleduihuan">兑换套餐</div>
							<div class="sub-btn" @click="handleOrder">确定购买</div>
						</div>
					</div>
				</div>
				<div class="orderlist_box" v-if="bookdtypeindex==2">
				    <div class="btn-group">
				       <div class="btntype flexrow">
				       	<div style="margin-right: 16px;" @click="handleordertype(0)">
				       		<el-button v-if="orderindex!=0">全部</el-button>
				       		<el-button v-if="orderindex==0" type="primary" plain>全部{{orderList.length}}</el-button>
				       	</div>
				       	<div style="margin-right: 16px;" @click="handleordertype(1)">
				       		<el-button v-if="orderindex!=1">已支付</el-button>
				       		<el-button v-if="orderindex==1" type="primary" plain>已支付{{orderList.length}}</el-button>
				       	</div>
				       	<div style="margin-right: 16px;" @click="handleordertype(2)">
				       		<el-button v-if="orderindex!=2">已兑换</el-button>
				       		<el-button v-if="orderindex==2" type="primary" plain>已兑换{{orderList.length}}</el-button>
				       	</div>
				       	<!-- <div @click="handleordertype(3)">
				       		<el-button v-if="orderindex!=3">已失效</el-button>
				       		<el-button v-if="orderindex==3" type="primary" plain>已失效{{orderList.length}}</el-button>
				       	</div> -->
				       </div>
				        <div class="dh_btn" @click="handleduihuan">
				            <img src="@/assets/static/duihuanicon.png" class="icon" srcset="">
							<div class="text">兑换套餐</div>
				        </div>
				    </div>
				
				    <div class="order-list">
				        <div class="order-item" v-for="(item, index) in orderList" :key="index">
				            <div class="top-col">
				                <div class="order-num">
				                    <img src="@/assets/static/order_icon.png" alt="" srcset="">
				                    <span>订单编号：{{item.orderNum}}</span>
				                </div>
				
				                <div class="date">{{item.createDate}}</div>
				            </div>
				            <div class="bottom-col">
				                <div class="layout1">
				                    <div class="book-title">{{item.booksGoods.goodsName}}</div>
				                    <div class="book-desc">{{item.extraObj || ""}}</div>
				                    <div class="book-origin-price">￥{{item.booksGoods.goodsOriPrice}}</div>
				                </div>
				                <div class="layout2">￥{{item.booksGoods.goodsPrice}}</div>
				                <div class="layout3 mauto">
				                    <div class="sub-btn" v-if="item.orderState==2">支付成功</div>
									<div class="sub-btn" v-if="item.orderState==3">兑换成功</div>
				                   <!-- <span v-if="index!==1">申请退款</span> -->
				                </div>
				            </div>
				        </div>
				    </div>
					<!-- 空 -->
					<div class="data_empty_box" style="padding-top: 68px;" v-if="orderList.length<=0">
						<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
						<div class="text">暂无数据</div>
					</div>
					<!-- 空 -->
				</div>
				
			</div>
		</div>	

		<div class="block30"></div>
		<!-- 支付页面 -->
		<doPay ref="refdoPay" :isShow="isShowPay" :payType="payType" :min-price="goodsPrice" @clickpayclose="clickpayclose" />
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from "vuex";
	import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
	import doPay from '@/components/doPay/doPay.vue';
	export default {
		components: {
			doPay
		},
		data() {
			return {
				bookdtypeList:["词书大全","套餐购买","我的订单","个人信息"],
				bookdtypeindex:0,//下标
				allbookdList:[],
				total:0,
				formData: {
					currentPage: 1,
					pageSize: 8,
				},
				vipgoods: [],
				checkedindex:0,//套餐下标
				isagree:true,//同意
				payType: 0, //下标 0是微信
				isShowPay: false, // 支付弹窗
				goodsPrice:0,
				orderindex:0,//下标
				orderList:[],//订单
			};
		},
		mounted: function() {
			if(this.$route.query&&this.$route.query.type){
				this.bookdtypeindex = this.$route.query.type
			}
			if(this.bookdtypeindex==2){
				this.handleordertype(0)
			}
			//词书大全
			this.getallbooklist()
			//商品套餐
			this.getbooksgoods()
		},
		computed: {
			...mapState(["userInfo", "isLogin", "myReadingObj",'vipTpyes','bsnName','myBooks']),
		},
		methods: {
			...mapMutations(["SET_Data","SET_USERINFO", "SET_ISLOGIN"]),
			navigato(name) {
				this.$router.push({
					name,
				});
			},
			//点击页数
			handleCurrentChange(currentPage) {
				this.formData.currentPage = currentPage;
				this.getallbooklist();
			},
			//词书大全
			getallbooklist(){
				var _this = this
				//查询内容
				var params = this.formData
				params["userUuid"] = this.userInfo.uuid
				showLoading()
				this.$http.post('app/word/books/index', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						var records = res.data.records;
						//获取类型json
						records.forEach((item, index) => {
							item["ifhave"] = -1
							try{
								item["ifhave"] = _this.myBooks.findIndex((ifitem) => { //这里的options就是上面遍历的数据源
									return ifitem.bookId == item.id //筛选出匹配数据
								});
							}catch(e){}
						});
						//console.log(records)
						_this.total = res.data.total;
						_this.allbookdList = records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//商品下标
			changeSelect(index) {
				this.checkedindex = index
			},
			//下标
			handlebookdtype(type){
				if(type==3){
					this.$router.push({
						name:"mine",
					});
					return;
				}
				this.bookdtypeindex = type
				if(type==2){
					this.handleordertype(0)
				}
			},
			//订单下标
			handleordertype(type){
				this.orderindex = type
				this.orderList = []
				this.getallorder()
			},
			//支付
			handleOrder(){
				var _this = this
				if(!this.isagree){
					this.$message.error("请勾选同意协议")
					return false;
				}
				var params = {
					goodsId: this.vipgoods[this.checkedindex].id,
					payType:0,//支付类型 0微信 1支付宝
					userUuid:this.userInfo.uuid
				}
				showLoading();
				this.$http.post("pay/books-orders/CreateOrders", params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						//继续支付
						_this.dopay(res)
					} else {
						hideLoading();
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			// 发动支付
			dopay(res) {
				var _this = this
				this.goodsPrice = this.vipgoods[this.checkedindex].goodsPrice
				this.isShowPay = true
				setTimeout(function() {
					_this.$refs.refdoPay.creatQrCode(res.data.payMsg)
				}, 800)
				hideLoading();
			},
			// 取消支付
			clickpayclose() {
				var _this = this
				this.$confirm('此操作将关闭支付弹窗，请确定已经支付成功，关闭后，将会跳转回课程详情页面，是否继续?', '提示', {
						confirmButtonText: '确定支付成功',
						cancelButtonText: '已取消支付',
						type: 'warning'
					}).then(() => {
						_this.isShowPay = false
						//刷新学习词书
						_this.getMyReading()
						//我的词书
						_this.getMyBooks()
						//普通订单
						_this.handlebookdtype(2)
					})
					.catch(() => {
						_this.isShowPay = false
						_this.$message({
							type: 'info',
							message: '已取消'
						})
					})
			},
			//学习词书
			getMyReading(){
				var _this = this
				var params = {
					userUuid:this.userInfo.uuid
				}
				_this.$http.post("app/word/userBooks/getMyReading", params).then(function(res) {		
					if (res.code === 200) {
						if(res.data){
							_this.SET_Data({ key: 'myReadingObj', value:res.data})
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//我的词书
			getMyBooks(){
				var _this = this
				var params = {
					userUuid:this.userInfo.uuid
				}
				_this.$http.post("app/word/userBooks/getMyBooks", params).then(function(res) {		
					if (res.code === 200) {
						if(res.data){
							_this.SET_Data({ key: 'myBooks', value:res.data})
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//商品套餐
			getbooksgoods() {
				var _this = this
				var params = {
					currentPage: 1,
					pageSize: 88
				}
				this.$http.post('app/word/orders/books-goods/getPage', params).then(function(res) {		
					if (res.code === 200) {
						var records = res.data.records
						//var vipgoods = []
						// records.forEach((item, index) => {
						// 	item["showbuy"] = true
						// 	if(item.userLevel>_this.userInfo.level){
						// 		item["showbuy"] = true
						// 	}else{
						// 		item["showbuy"] = false
						// 	}
						// 	vipgoods.push(item)
						// });
						_this.vipgoods = records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//退款
			handletuikuan(index){
				this.$alert('您好，请在首页添加客服进行退款！', '提示', {
				    confirmButtonText: '确定',
				    callback: action => {}
				});
			},
			//兑换套餐
			handleduihuan(){
				var _this = this
				this.$prompt('请输入兑换码', '兑换套餐', {
				        confirmButtonText: '确定兑换',
				        cancelButtonText: '取消',
				        inputPlaceholder:"请输入"
				    }).then(({ value }) => {
						if(value){
							var params = {
								code: value.trim(),
								userUuid: _this.userInfo.uuid
							}
							_this.$http.post('app/word/booksGoods/code/geUserVipByCode', params).then(function(res) {
								//数据处理
								if (res.code == 200) {
									//刷新学习词书
									_this.getMyReading()
									//我的词书
									_this.getMyBooks()
									_this.$alert('兑换成功！', '提示', {
									    confirmButtonText: '确定',
									    callback: action => {}
									});
									//普通订单
									_this.handlebookdtype(2)
								} else {
									_this.$confirm(res.message, '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										type: 'warning'
									}).then(() => {}).catch(() => {});
								}
							})
						}else{
							_this.$message({
								type: 'error',
								message: '请输入兑换码 '
							});
						}
						
				    }).catch(() => {
				        this.$message({
				            type: 'info',
				            message: '取消输入'
				        });       
				});
			},
			//刷新用户信息
			refreshUser(){
				var _this = this
				var params = {
					id: this.userInfo.id
				}
				this.$http.post('app/hlexam/hlUser/detail', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.SET_Data({ key: 'userInfo', value:res.data})
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//请求数据
			getallorder(){
				var _this = this
				var params = {
					currentPage: 1,
					pageSize: 888
				}
				params["userUuid"] = this.userInfo.uuid
				if(this.orderindex==0){
					params["orderState"] = [2,3]
				}
				if(this.orderindex==1){
					params["orderState"] = [2]
				}
				if(this.orderindex==2){
					params["orderState"] = [3]
				}
				if(this.orderindex==3){
					params["orderState"] = [0,1]
				}
				this.$http.post('app/word/orders/books-orders/PageBooksOrders', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data.records
						// records.forEach((item, index) => {
							
						// });
						_this.orderList = records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			
		},
	};
</script>
<style lang="scss" scoped>
	@mixin text-line($lineNum) {
		//定义超出多少行就省略
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: $lineNum;
		line-clamp: $lineNum;
		-webkit-box-orient: vertical;
	}
	.allbooks_page{
		position: relative;
		z-index: 2;
	}
	.paginationbox{
		width: 100%;
		padding: 18px 0;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 28px;
	}
	.book_conbox{
		width: 100%;
		padding: 32px 14px;
		box-sizing: border-box;
		border-radius: 20px;
		background: #FFF;
		.titlename_box{
			margin: 0 10px;
			padding: 5px;
			display: flex;
			align-items: center;
			border-radius: 100px;
			border: 1px solid #E5E6EB;
			.itemobj{
				width: 80px;
				height: 28px;
				line-height: 28px;
				text-align: center;
				border-radius: 100px;
				background: #ffffff;
				color: #666;
				cursor: pointer;
			}
			.active{
				background: #3773F5;
				color: #ffffff;
			}
		}
		.allbooklist_box{
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			.itemobj{
				margin: 20px 10px 0;
				width: 273px;
				border-radius: 16px;
				background: #F2F6FF;
				padding: 20px;
				box-sizing: border-box;
				position: relative;
				.tips{
					width: 80px;
					height: 37px;
					border-radius: 16px 0px;
					background: linear-gradient(180deg, #5EA9FF 0%, #1C85FF 100%);
					color: #FFF;
					line-height: 37px;
					text-align: center;
					position: absolute;
					left: 0;
					top: 0;
					z-index: 2;
					&.active{
						background: linear-gradient(180deg, #FFBF1A 0%, #FF7C1C 100%);
					}
				}
				.imgbox{
					width: 100%;
					height: 271px;
					border-radius: 8px;
					overflow: hidden;
				}
				.titlename{
					width: 100%;
					margin-top: 18px;
					padding: 2px 0;
					text-align: center;
					color: #183E87;
				}
			}
		}
		.goodslist_bos{
			width: 100%;
			.vip-group {
				display: flex;
				flex-wrap: wrap;
				margin: 8px;
				.vip-item {
					margin: 20px 10px 0;
					display: flex;
					align-items: center;
					flex-direction: column;
					border-radius: 8px;
					width: 210px;
					padding: 20px 0;
					border: 1px solid #B7D9FF;
					background: #F2F6FF;
					position: relative;
					box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.07);
					cursor: pointer;
					.vip-label {
						color: #222;
						margin-top: 25px;
					}
					.price-text {
						color: #222;
						margin-top: 8px;
						font-weight: bold;
						letter-spacing: 2px;
					}
			
					.origin-price {
						color: #999;
						margin-top: 8px;
						text-decoration: line-through;
						opacity: 0.5;
					}
			
					.yh-img {
						width: 68px;
						height: 26px;
						position: absolute;
						right: 0;
						top: 0;
						z-index: 2;
						border-radius: 8px;
						border: 1px solid #B7D9FF;
						background: linear-gradient(97deg, #FDEBD9 14.73%, #FEBD7C 89.81%);
						text-align: center;
						line-height: 26px;
					}
				}
				.vip-item-checked {
					border: 2px solid #0D7EFF !important;
					box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.07) !important;
					background: #fff !important;
				}
			}
			.desc-content {
				margin: 0 16px;
			
				&:last-child {
					margin-top: 16px;
				}
				.desc-title {
					font-size: 24px;
					font-weight: bold;
					border-left: solid 3px #51D9A5;
					color: #222;
					padding-left: 8px;
					margin-bottom: 12px
				}
			
				.desc-item {
					margin-top: 5px;
					color: #333333;
					font-size: 16px;
					white-space:pre;
				}
				.desc-itemlist{
					display: flex;
					flex-wrap: wrap;
					.itemobj{
						margin-top: 5px;
						margin-right: 10px;
						height: 35px;
						line-height: 35px;
						padding: 0 12px;
						border-radius: 4px;
						background: #F2F6FF;
						font-size: 16px;
					}
				}
			}
			.bottom-group {
				width: 358px;
				display: flex;
				align-items: center;
				flex-direction: column;
				margin-left: 10px;
				.first-col {
					margin-top: 28px;
					color:#603C0A;
					font-size: 16px;
					display: flex;
					align-items: center;
					.radio {
						transform: scale(1.1);
						margin-bottom: 0px;
						padding-right: 8px;
					}
				}
				
				.btnlist{
					height: 48px;
					width: 100%;
					display: flex;
					align-items: center;
					margin-top: 18px;
				}
				.sub-btn {
					height: 40px;
					width: 122px;
					line-height: 40px;
					text-align: center;
					border-radius: 4px;
					background: #3773F5;
					box-shadow: 0px -2px 0px 0px #3E97FE inset;
					color:#ffffff;
					font-size: 16px;
					cursor: pointer;
					margin-left: 12px;
				}
				.sub-duihuan {
					height: 40px;
					line-height: 40px;
					text-align: center;
					width: 122px;
					border-radius: 4px;
					background: #07D3AB;
					box-shadow: 0px -2px 0px 0px #41BE8E inset;
					color:#ffffff;
					font-size: 16px;
					cursor: pointer;
					margin-left: 12px;
				}
			}
		}
		.orderlist_box{
			width: 100%;
			padding: 0 10px;
			box-sizing: border-box;
			.btn-group {
			    margin-top: 28px;
			    display: flex;
				align-items: center;
			    .btn-item {
			        width: 88px;
			        height: 41px;
			        margin-right: 16px;
			        margin-left: 0;
			
			        span:last-child {
			            margin-left: 2px;
			        }
			    }
			
			    .checked-item {
			        background: #E5EAFA;
			        color: #1682FD;
			    }
			
			    .dh_btn {
			        margin-left: auto;
			        width: 120px;
			        height: 42px;
			        cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 8px;
					background: #298DFF;
					box-shadow: 0px -2px 0px 0px #0D7EFF inset;
			        &:hover {
			            transform: scale(1.01);
			        }
					.icon{
						width: 16px;
						height: 16px;
					}
					.text{
						margin-left: 5px;
						color: #FFF;
						font-size: 15px;
					}
			    }
			}
			
			.order-list {
			    margin-top: 20px;
			
			    .order-item {
			        width: 1000px;
			        height: 214px;
			        border-radius: 16px;
			        box-shadow: 0px 3px 15.5px 0px #0000001A;
			        background: #fff;
			        margin-bottom: 20px;
			    }
			
			    .top-col {
			        height: 60px;
			        display: flex;
			        align-items: center;
			        border-bottom: 1px solid #F0F0F0;
			        padding: 0 24px;
			        color: #666;
			
			        img {
			            width: 20px;
			            height: 20px;
			            margin-right: 8px;
			        }
			
			        .date {
			            margin-left: auto;
			            color: #666;
			            font-size: 14px;
			        }
			    }
			
			    .bottom-col {
			        height: 154px;
			        align-items: center;
			        display: flex;
			        padding: 0 24px;
			
			
			        img {
			            width: 185px;
			            height: 100px;
			            flex-shrink: 0;
			            border-radius: 6px;
			        }
			
			        .layout1 {
			            margin-left: 18px;
			            height: 100px;
			            width: 320px;
			
			            .book-title {
			                font-size: 20px;
			                font-weight: bold;
			                color: #5C3704;
			            }
			
			            .book-desc {
			                color: #666666;
			                margin-top: 10px;
			                height: 42px;
			                font-size: 14px;
			                line-height: 18px;
			                @include text-line(2);
			            }
			
			            .book-origin-price {
			                font-size: 16px;
			                color: #666;
			                text-decoration: line-through;
			                opacity: 0.6;
			            }
			        }
			
			        .layout2 {
			            color: #F00;
			            font-size: 16px;
			            font-weight: bold;
			            height: 100px;
			            width: 165px;
			            flex-shrink: 0;
			            display: flex;
			            align-items: center;
			            justify-content: center;
			            margin-left: 50px;
			            border-left: 1px solid #F0F0F0;
			            border-right: 1px solid #F0F0F0;
			        }
			
			
			        .layout3 {
			            display: flex;
			            flex-direction: column;
			            align-items: center;
			            justify-content: center;
			            margin-left: 89px;
			            height: 100px;
			            flex-shrink: 0;
			
			            .sub-btn {
			                height: 40px;
			                display: flex;
			                justify-content: center;
			                align-items: center;
			                font-size: 14px;
			                flex-shrink: 0;
			                border-radius: 20px;
			                background: #FE8C22;
			                box-shadow: 0px -2px 0px 0px #F07400 inset;
			                color: #fff;
			                line-height: 40px;
			                width: 118px;
			                cursor: default;
			            }
			
			            span {
			                margin-top: 12px;
			                font-size: 16px;
			                color: #666;
			                cursor: pointer;
			
			                &:hover {
			                    text-decoration: underline;
			                }
			            }
			        }
			    }
			
			}
		}
	}
</style>